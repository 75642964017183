export const PRODUCTS = [
  {
    id: 1,
    name: "Bobinas comerciales",
    type: "Lisas o personalizadas",
    description:
      "<p>Nuestras bobinas permiten su uso en varias industrias, como ser: alimenticia, regaleria, pintura, construccion, etc. otorgando a su packaging un sello personal y distintivo.</p><h4>Medidas:</h4><p>20 cm - 40 cm - 60 cm - 80 cm</p><h4>Papeles:</h4><ul><li>Papel Kraft</li><li>Papel Sulfito</li><li>Papel Mejorado</li><li>Papel Cristal</li></ul>",
    images: [
      "/images/products/bobinas-comerciales/1.webp",
      "/images/products/bobinas-comerciales/2.webp",
      "/images/products/bobinas-comerciales/3.webp",
      "/images/products/bobinas-comerciales/4.webp",
    ],
    categoryId: 1,
  },
  {
    id: 2,
    name: "Bolsas <span>- Fondo cuadrado</span>",
    type: "Lisas o personalizadas",
    description:
      "<p>El clásico y popular formato de base rectangular, indispensable para el hogar, gastronomía, ferreterías y diferentes tipos de tiendas. Adhesivos aptos para alimentos.</p><h4>Medidas:</h4><p>Anchos: 20 cm - 22 cm - 25 cm</p><p>Alto: desde 26 cm a 52 cm</p><h4>Papeles:</h4><ul><li>1 pliego de 80 gr/m2</li><li>1 pliego de 125 gr/m2</li><li>2 pliegos de 80 gr/m2 c/u</li></ul>",
    images: [
      "/images/products/bolsas-de-papel/fondo-cuadrado/1.webp",
      "/images/products/bolsas-de-papel/fondo-cuadrado/2.webp",
      "/images/products/bolsas-de-papel/fondo-cuadrado/3.webp",
      "/images/products/bolsas-de-papel/fondo-cuadrado/4.webp",
      "/images/products/bolsas-de-papel/fondo-cuadrado/5.webp",
      "/images/products/bolsas-de-papel/fondo-cuadrado/6.webp",
    ],
    categoryId: 5,
  },
  {
    id: 3,
    name: "Bolsas <span>- Fondo americano</span>",
    type: "Lisas o personalizadas",
    description:
      "<p>Ofrecen una alta resistencia al peso. Compatibles para contacto alimentario. Poseen fuelle para contener productos de mayor tamaño.</p><h3>Pequeñas</h3><h4>Medidas:</h4><p>Anchos: desde 9,5 cm a 25 cm</p><p>Altos: desde 16 cm a 51 cm</p><h4>Papeles:</h4><ul><li>Kraft - marron - 48 gr/m2</li><li>Sulfito - blanco opaco - 53 gr/m2</li><li>Cristal - blanco brillante - 50 gr/m2</li></ul>",
    images: [
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/1.webp",
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/2.webp",
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/3.webp",
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/4.webp",
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/5.webp",
      "/images/products/bolsas-de-papel/fondo-americano/pequenas/6.webp",
    ],
    categoryId: 9,
  },
  {
    id: 4,
    name: "Bolsas <span>- Fondo americano</span>",
    type: "Lisas o personalizadas",
    description:
      "<p>Ofrecen una alta resistencia al peso. Compatibles para contacto alimentario. Poseen fuelle para contener productos de mayor tamaño.</p><h3>Grandes</h3><h4>Medidas:</h4><p>Anchos: desde 20 cm a 26 cm</p><p>Altos: desde 38 cm a 74 cm</p><h4>Papeles:</h4><ul><li>1 pliego de 80 gr/m2</li><li>1 pliego de 125 gr/m2</li><li>2 pliegos de 80 gr/m2 c/u</li></ul>",
    images: [
      "/images/products/bolsas-de-papel/fondo-americano/grandes/1.webp",
      "/images/products/bolsas-de-papel/fondo-americano/grandes/2.webp",
      "/images/products/bolsas-de-papel/fondo-americano/grandes/3.webp",
      "/images/products/bolsas-de-papel/fondo-americano/grandes/4.webp",
      "/images/products/bolsas-de-papel/fondo-americano/grandes/5.webp",
      "/images/products/bolsas-de-papel/fondo-americano/grandes/6.webp",
    ],
    categoryId: 10,
  },
  {
    id: 5,
    name: "Bolsas Carboneras",
    type: "Impresiones personalizadas",
    description:
      "<p>Bolsas para carbón con impresión estándar, en paquetes de 100 unidades. Fabricadas con papeles resistentes, en uno o dos pliegos.</p><h3>Fondo cuadrado</h3><h4>Medidas:</h4><p>Para 3, 4 y 5 kg.</p><h4>Papeles:</h4><ul><li>1 pliego de 125gr/m2</li></ul>",
    images: [
      "/images/products/bolsas-para-carbon/fondo-cuadrado/1.webp",
      "/images/products/bolsas-para-carbon/fondo-cuadrado/2.webp",
      "/images/products/bolsas-para-carbon/fondo-cuadrado/3.webp",
      "/images/products/bolsas-para-carbon/fondo-cuadrado/4.webp",
    ],
    categoryId: 7,
  },
  {
    id: 6,
    name: "Bolsas Carboneras",
    type: "Impresiones personalizadas",
    description:
      "<p>Bolsas para carbón con impresión estándar, en paquetes de 100 unidades. Fabricadas con papeles resistentes, en uno o dos pliegos.</p><h3>Fondo americano</h3><h4>Medidas:</h4><p>Para 2, 3, 4 y 5 kg.</p><h4>Papeles:</h4><ul><li>1 pliego de 125gr/m2</li><li>2 pliego de 80gr/m2</li></ul>",
    images: [
      "/images/products/bolsas-para-carbon/fondo-americano/1.webp",
      "/images/products/bolsas-para-carbon/fondo-americano/2.webp",
      "/images/products/bolsas-para-carbon/fondo-americano/3.webp",
      "/images/products/bolsas-para-carbon/fondo-americano/4.webp",
    ],
    categoryId: 8,
  },
];

export const CATEGORIES = [
  {
    id: 1,
    name: "Bobinas comerciales",
    childs: null,
  },
  {
    id: 2,
    name: "Bolsas de papel",
    childs: [
      {
        id: 5,
        name: "Fondo cuadrado",
      },
      {
        id: 6,
        name: "Fondo americano",
        subchilds: [
          {
            id: 9,
            name: "Pequeñas",
          },
          {
            id: 10,
            name: "Grandes",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    name: "Bolsas para carbón",
    childs: [
      {
        id: 7,
        name: "Fondo cuadrado",
      },
      {
        id: 8,
        name: "Fondo americano",
      },
    ],
  },
];
