import React from "react";

const HowBuy = () => {
  return (
    <>
      <h2 className="contact-title">
        <span className="bold-title">
          {" "}
          Brindamos un servicio integral y excepcional,
          <br />{" "}
        </span>
        garantizando el producto que mejor se adapte a vos.
      </h2>

      <div className="contact-info  ">
        <h3 className="contact-info-title m-0">
          <span className="title-line"></span>
          <span className="contact-info-title__content"> Cómo comprar</span>
          <span className="title-line"> </span>
        </h3>
        <div className="contact-info-column ">
          <a
            href="mailto:contacto@bolpackweb.com"
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 contact-info-column__item"
          >
            <i className="item-icon fas fa-envelope-open mr-2"></i>{" "}
            contacto@bolpackweb.com
          </a>
          <a
            href="https://wa.me/5493624241410"
            target="_blank"
            rel="noopener noreferrer"
            className="py-2 contact-info-column__item"
          >
            <i className="item-icon fab fa-whatsapp mr-2"></i> 362-4241410
          </a>
        </div>

        <div className="contact-info-column">
          <p className="py-2 contact-info-column__item address">
            <i className="item-icon fas fa-store mr-2"></i> Ruta 16 km 24
          </p>
          <p className="py-2 contact-info-column__item more-info">
            Parque Industrial Puerto Tirol
          </p>
          <p className="py-2 contact-info-column__item more-info">
            Lunes a Viernes de 8 a 17 horas
          </p>
        </div>
      </div>
    </>
  );
};

export default HowBuy;
