import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
// eslint-disable-next-line import/no-webpack-loader-syntax
import MapBoxWorker from "worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker";

mapboxgl.accessToken =
  "pk.eyJ1IjoiY2lyY3VsaXRvIiwiYSI6ImNsajV2Z3E2bTAxOGIzaW41dW1jMjN0anAifQ.gmsLUjXvcswYIFhmBV05Og";
const lng = -59.06193697419979;
const lat = -27.362342727753553;
const zoom = 14;
mapboxgl.workerClass = MapBoxWorker;

const MapComponent = () => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useEffect(() => {
    if (map.current) return; // initialize map only once
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/light-v11",
      center: [lng, lat],
      zoom: zoom,
      cooperativeGestures: true,
    });

    const marker = document.createElement("div");
    const width = "140px";

    marker.style.width = width;
    marker.style.height = "42px";
    marker.style.backgroundImage = "url(/images/svg/bolpackmarker.svg)";
    marker.style.backgroundRepeat = "no-repeat";
    marker.style.backgroundSize = "cover";

    new mapboxgl.Marker(marker)
      .setLngLat([-59.06193697419979, -27.365342727753553])
      .setOffset([-55, -15])
      .addTo(map.current);
  });

  return (
    <div className="map-container">
      <div
        ref={mapContainer}
        className="map"
        style={{ width: "100%", height: "600px" }}
      ></div>
    </div>
  );
};

export default MapComponent;
