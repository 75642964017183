import React, { useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";

function Form() {
  const [sent, setSent] = useState(false);
  const [notSent, setNotSent] = useState(false);
  const [sending, setSending] = useState(false);
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = (values) => {
    setSending(true);

    const form = new FormData();

    form.append("name", values.name);
    form.append("email", values.email);
    form.append("phone", values.phone);
    form.append("product", values.product);
    form.append("message", values.message);

    axios
      .post("https://www.bolpackweb.com/php/sending-contact.php", form)
      .then((res) => {
        if (res.status === 200) {
          setSent(true);
          document.querySelector("#form-contact").reset();
        } else {
          setNotSent(true);
        }
      })
      .catch((error) => {
        console.log("error:", error);
        setNotSent(true);
      })
      .finally(() => {
        setSending(false);
      });
  };

  return (
    <div
      className="contact__card"
      data-aos="zoom-in"
      style={{ border: "3px solid #660000", zIndex: "200", position: "relative" }}
    >
      <header>
        <h3
          style={{
            color: "#660000",
            fontSize: "1.5rem",
            margin: "1.5rem 0",
            fontWeight: "normal",
          }}
        >
          CONTACTANOS
        </h3>
      </header>
      <form onSubmit={handleSubmit(onSubmit)} id="form-contact">
        <div className="form-row">
          <div className="form-group col-12 col-lg-6">
            {/* <label className='name'>Nombre</label> */}
            <input
              type="text"
              name="name"
              placeholder="Nombre"
              className={errors.name ? "form-control is-invalid" : "form-control"}
              ref={register({
                required: "Required",
              })}
            />
            {errors.name && (
              <div className="invalid-feedback">Debes ingresar un Nombre.</div>
            )}
          </div>
          <div className="form-group col-12 col-lg-6">
            {/* <label className='phone'>Teléfono</label> */}
            <input
              type="text"
              name="phone"
              placeholder="Teléfono"
              className={errors.phone ? "form-control is-invalid" : "form-control"}
              ref={register({
                required: "Required",
              })}
            />
            {errors.phone && (
              <div className="invalid-feedback">
                Debes ingresar un número de Teléfono.
              </div>
            )}
          </div>
          <div className="form-group col-12 col-lg-6">
            {/* <label className='email'>Correo Electrónoco</label> */}
            <input
              type="email"
              name="email"
              placeholder="Correo Electrónico"
              className={errors.email ? "form-control is-invalid" : "form-control"}
              ref={register({
                required: "Required",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                },
              })}
            />
            {errors.email && (
              <div className="invalid-feedback">Debes ingresar un emial válido.</div>
            )}
          </div>
          <div className="form-group col-12 col-lg-6">
            <select
              className="form-control"
              name="product"
              id="select-product"
              ref={register}
            >
              <option>Producto</option>
              <option value="Bolsas para carbón">Bolsas para carbón</option>
              <option value="Bolsas para panadería">Bolsas para panadería</option>
              <option value="Bolsas para boutique">Bolsas para boutique</option>
              <option value="Bobinas de papel">Bobinas de papel</option>
              <option value="Bolsas Personalizadas">Bolsas Personalizadas</option>
            </select>
          </div>
          <div className="form-group col-12">
            {/* <label className='message'>Mensaje</label> */}
            <textarea
              className="form-control"
              name="message"
              id="message"
              placeholder="Mensaje"
              rows="6"
              ref={register}
            ></textarea>
          </div>
          {sent && (
            <div className="alert alert-success" role="alert">
              <strong>¡Gracias!</strong> Tu mensaje se envío correctamente{" "}
            </div>
          )}
          {notSent && (
            <div className="alert alert-danger" role="alert">
              <strong>¡Ups!</strong> Algo salió mal, intenta de nuevo.{" "}
            </div>
          )}
          <div className="form-group col-12 text-lg-center">
            <button type="submit" className="btn btn-loquesea">
              {sending && (
                <div className="spinner-border text-light" role="status">
                  {" "}
                  <span className="sr-only">Loading...</span>{" "}
                </div>
              )}
              Enviar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Form;
