import React from "react";

export default function CustomPrints() {
  return (
    <div className="container">
      <div className="custom-prints__content">
        <h2 className="custom-prints__headline">Impresiones personalizadas</h2>
        <p className="custom-prints__description">
          Contamos con asesores que pueden ayudarte a personalizar tu impresión, para
          aplicarla sobre cualquiera de nuestros productos.
        </p>
        <h3 className="custom-prints__closing-text">Flexografía en 1, 2 y 3 colores</h3>
      </div>
      <div className="custom-prints__images">
        <img
          className="custom-prints__image"
          src="/images/products/custom-prints/1.webp"
          alt="Impresión personalizada 1"
        />
        <img
          className="custom-prints__image"
          src="/images/products/custom-prints/2.webp"
          alt="Impresión personalizada 2"
        />
      </div>
    </div>
  );
}
