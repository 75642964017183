import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Navbar from '../layout/Navbar';
// import Loader from '../layout/Loader';
import Footer from '../layout/Footer';
import Home from '../views/Home';
import AOS from 'aos';
import 'aos/dist/aos.css';
import WhatsApp from '../partials/WhatsApp';

function Routes() {
  AOS.init();
  return (
    <BrowserRouter>
      {/* <Loader /> */}
      <Navbar />
      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='*' component={Error} />
      </Switch>
      <WhatsApp/>
      <Footer />
    </BrowserRouter>
  );
}

export default Routes;
