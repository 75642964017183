import React from 'react';

const WhatsApp = () => {
  return(
    <a href='https://wa.me/5493624241410' target='_blank' rel='noopener noreferrer' className='whatsapp-button'>
      <i className='fab fa-whatsapp fa-2xl'></i>
    </a>
  )
};

export default WhatsApp;