import React from 'react';

function Footer() {
  return (
    <footer className='footer' >
      <div className='footer__top'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <ul>
                <a href='mailto:contacto@bolpackweb.com' target='_blank' rel="noopener noreferrer">
                  <li>
                    <i className="fal fa-envelope"></i>
                    contacto@bolpackweb.com
                  </li>
                </a>
                <a href='https://wa.me/5493624241410' target='_blank' rel="noopener noreferrer">
                  <li>
                    <i className="fab fa-whatsapp"></i>
                    362 4241410
                  </li>
                </a>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='footer__bottom'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <a href='https://circulo.digital/' target='_blank' rel="noopener noreferrer">
                <img className='' src='../images/svg/firma-circulo.svg' alt='Creado por circulo' />
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

// phone 0362-4760538 envelope papeleranorteventas@gmail.com whatsapp 362 4241410
export default Footer;