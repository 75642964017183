import React from "react";

import HowBuy from "../layout/HowBuy";
import MapComponent from "../layout/MapComponent";
import GalleryAboutUs from "../partials/GalleryAboutUs";
import Form from "../partials/Form";
import Products from "../partials/Products.jsx";
import CustomPrints from "../partials/CustomPrints.jsx";

function Home() {
  return (
    <main>
      <div className="w-100 h-auto position-relative home-header">
        <img src="../images/webp/header.webp" alt="productos" className="header-image" />
        <div className="position-absolute row content align-content-center header-text">
          <div className="col-12 text-center">
            <h3 className="mx-auto">Bienvenido a Bolpack</h3>
          </div>
          <div className="col-12 text-center pt-2">
            <h1 className="mx-auto">Somos expertos en bolsas y bobinas de papel</h1>
          </div>
        </div>
      </div>
      <section className="about-us" id="about-us">
        <div className="container">
          <div className="row justify-content-center align-items-center">
            <div className="col-12 col-lg-7 col-xl-8 py-0" data-aos="fade-right">
              <h2 className="mb-4">Nosotros</h2>
              <p>
                Desde 1979,{" "}
                <b>Bolpack es tu socio confiable en soluciones de embalaje y papel</b>.
                Nos destacamos por nuestra calidad, compromiso con la sostenibilidad y
                servicios excepcionales. Ofrecemos envases de papel de primera categoría
                para diversos sectores del mercado argentino y mundial. Nuestro enfoque se
                basa en la mejora continua, la flexibilidad y la adaptabilidad.{" "}
                <b>
                  Nos esforzamos por mantener nuestro equipamiento productivo actualizado
                  y priorizamos la sostenibilidad en nuestra cadena de producción.
                </b>{" "}
                Valoramos la calidad, el servicio al cliente y buscamos expandir nuestra
                presencia en nuevos mercados. Somos un equipo profesional y comprometido
                con construir un futuro sostenible para todos.
              </p>
            </div>
            <GalleryAboutUs />
          </div>
        </div>
      </section>
      <section className="our-philosophy">
        <img
          src="../images/webp/bosque.webp"
          className="w-100 our-philosophy-image"
          alt="bosque arboles"
        />
        <div className="our-philosophy-overlay py-4">
          <div className="container h-100 py-4">
            <div className="row justify-content-center py-4">
              <div className="col-12 col-md-8 col-xl-10">
                <h1>Nuestra filosofía</h1>
              </div>
            </div>
            <div className="row justify-content-center py-4">
              <div className="col-12 col-md-8 col-xl-10">
                <div className="row align-items-center py-4">
                  <div className="col-2 flex align-items-center text-right">
                    <img src="../images/svg/filosofia-icon-1.svg" alt="materiales" />
                  </div>
                  <div className="col-10">
                    <p>
                      Utilizamos materiales sostenibles y minimizamos los residuos en la
                      producción.
                    </p>
                  </div>
                </div>
                <div className="row align-items-center py-4">
                  <div className="col-2 flex align-items-center text-right">
                    <img src="../images/svg/filosofia-icon-2.svg" alt="materiales" />
                  </div>
                  <div className="col-10">
                    <p>
                      Aprovechamos responsablemente las energías y fuentes renovables.
                    </p>
                  </div>
                </div>
                <div className="row align-items-center py-4">
                  <div className="col-2 flex align-items-center text-right">
                    <img src="../images/svg/filosofia-icon-3.svg" alt="materiales" />
                  </div>
                  <div className="col-10">
                    <p>
                      Valoramos el reciclaje, renovando el ciclo de vida de nuestros
                      elementos.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="products" id="products">
        <Products />
      </section>

      <section className="custom-prints">
        <CustomPrints />
      </section>

      <section className="how-to-buy-container" id="how-to-buy">
        <HowBuy />
      </section>

      <section id="contact" className="contact-container">
        <div className="form-container">
          <Form />
        </div>
        <MapComponent />
      </section>
    </main>
  );
}

export default Home;
