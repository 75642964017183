import React, { useEffect, useRef, useState } from "react";

const Navbar = () => {
  const windowWidthRef = useRef(window.innerWidth);
  const headerRef = useRef(null);
  const navbarRef = useRef(null);
  const headerItemGroupRef = useRef(null);
  const [active, setActive] = useState(false);

  const handleScroll = () => {
    const isScrolled = document.documentElement.scrollTop > 0;
    headerRef.current.classList.toggle("active", isScrolled);
    setActive(isScrolled);
  };

  const openMenu = () => {
    navbarRef.current.classList.toggle("nav-open");
    headerItemGroupRef.current.classList.toggle("active");
  };

  const handleScrollToElement = (element) => {
    const topElem = document.getElementById(element);
    const top = topElem.offsetTop;
    console.log(top);
    const isHeaderActive = headerItemGroupRef.current.classList.contains("active");

    if (isHeaderActive) {
      navbarRef.current.classList.toggle("nav-open");
      headerItemGroupRef.current.classList.toggle("active");
    }

    if (element === 'contact') {

      window.scrollTo({ top: top - 420, behavior: "smooth" });
    } else {

      window.scrollTo({ top: top, behavior: "smooth" });
    }
  };

  useEffect(() => {
    const handleResize = () => {
      windowWidthRef.current = window.innerWidth;
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <nav className="header" ref={headerRef}>
        <div className="container">
          <div className="row justify-content-between flex-lg-row-reverse">
            <div className="col-6 col-lg-9 align-self-center">
              <div className="botton-menu" onClick={openMenu}>
                <div className="navbar navbar box-shadow" ref={navbarRef}>
                  <div className="nav-button">
                    <span id="nav-icon3">
                      <span></span>
                      <span></span>
                      <span></span>
                      <span></span>
                    </span>
                  </div>
                </div>
              </div>
              <ul
                className="header-item-group d-lg-flex justify-lg-content-between"
                ref={headerItemGroupRef}
              >
                <li
                  className="header-item-group__item"
                  onClick={() => handleScrollToElement("products")}
                >
                  Productos
                </li>
                <li
                  className="header-item-group__item"
                  onClick={() => handleScrollToElement("about-us")}
                >
                  Nosotros
                </li>
                <li
                  className="header-item-group__item"
                  onClick={() => handleScrollToElement("how-to-buy")}
                >
                  Cómo comprar
                </li>
                <li
                  className="header-item-group__item line-bottom"
                  onClick={() => handleScrollToElement("contact")}
                >
                  Contacto
                </li>
                <li className="header-item-group__item rrss fb text-lg-right">
                  <a
                    href="https://wa.me/5493624241410"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <i className="fab fa-whatsapp"></i>
                    </span>
                  </a>
                </li>
                <li className="header-item-group__item rrss fb text-lg-right">
                  <a
                    href="https://www.facebook.com/Bolpackweb/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <i className="fab fa-facebook-square"></i>
                    </span>
                  </a>
                </li>
                <li className="header-item-group__item rrss ig text-lg-right">
                  <a
                    href="https://www.instagram.com/bolpackweb/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>
                      <i className="fab fa-instagram"></i>
                    </span>
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-6 col-lg-3 text-right text-lg-left align-self-center">
              <a href="/">
                <img
                  src={
                    windowWidthRef.current <= 991 || active
                      ? "../images/svg/logo-bolpack-transparente.svg"
                      : "../images/svg/logo-bolpack-white-transparente.svg"
                  }
                  alt="Logo Bolpack"
                  className="img-fluid header__logo ml-auto ml-lg-0"
                />
              </a>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
