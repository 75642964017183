import React, { useCallback, useEffect, useState } from "react";
import clsx from "clsx";
import { CATEGORIES, PRODUCTS } from "../../constants/products";

export default function Products() {
  const [showDropdown, setShowDropdown] = useState(false);
  const [activeCategoryId, setActiveCategoryId] = useState(null);
  const [activeProduct, setActiveProduct] = useState(PRODUCTS[0]);
  const [activeImage, setActiveImage] = useState(activeProduct.images[0]);
  const [headlineText, setHeadlineText] = useState("");
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1280);

  const findProductByCategoryId = useCallback((categoryId) => {
    return PRODUCTS.find((product) => product.categoryId === categoryId);
  }, []);

  const updateHeadlineText = useCallback((category) => {
    CATEGORIES.forEach((parentCategory) => {
      if (parentCategory.childs) {
        parentCategory.childs.forEach((child) => {
          if (child.id === category.id) {
            return setHeadlineText(`${parentCategory.name} - ${child.name}`);
          }

          if (child.subchilds) {
            child.subchilds.forEach((subchild) => {
              if (subchild.id === category.id) {
                return setHeadlineText(`${child.name} - ${subchild.name}`);
              }
            });
          }
        });
      }
    });
  }, []);

  const handleClickParentCategory = ({ category }) => {
    const hasChilds = category.childs && category.childs.length > 0;

    if (!hasChilds) {
      const activeProduct = findProductByCategoryId(category.id);

      setActiveProduct(activeProduct);
      setActiveImage(activeProduct.images[0]);

      if (!isDesktop) {
        setHeadlineText(category.name);
        setShowDropdown(false);
      }
    } else {
      setActiveCategoryId((prevActiveItem) =>
        prevActiveItem !== category.id ? category.id : null
      );
    }
  };

  const handleClickChildCategory = ({ category }) => {
    const hasSubchilds = category.subchilds && category.subchilds.length > 0;

    if (hasSubchilds) {
      const firstSubchild = category.subchilds[0];

      const activeProduct = findProductByCategoryId(firstSubchild.id);

      setActiveProduct(activeProduct);
      setActiveImage(activeProduct.images[0]);

      if (!isDesktop) updateHeadlineText(firstSubchild);
    } else {
      const activeProduct = findProductByCategoryId(category.id);

      setActiveProduct(activeProduct);
      setActiveImage(activeProduct.images[0]);

      if (!isDesktop) updateHeadlineText(category);
    }

    setShowDropdown(false);
  };

  useEffect(() => {
    if (isDesktop) {
      setHeadlineText("Categorías:");
    } else {
      setHeadlineText("Seleccionar categoría");
    }

    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1280);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, [isDesktop]);

  return (
    <div className="container">
      <header className="products__header">
        <h2 className="products__header-headline">Nuestros Productos</h2>
        {isDesktop && (
          <a href="/pdf/catalogo.pdf" className="products__header-download">
            Descargar catálogo
          </a>
        )}
      </header>
      <div className="products__container">
        <div className="products__categories">
          <h3
            className={clsx(
              "products__categories-headline",
              !isDesktop && !showDropdown && "mb-0"
            )}
            onClick={() => setShowDropdown(!showDropdown)}
          >
            <span
              className={clsx(
                !isDesktop &&
                  headlineText !== "Seleccionar categoría" &&
                  "products__categories-headline--active",
                !isDesktop && !showDropdown && "products__categories-headline--show",
                !isDesktop && showDropdown && "products__categories-headline--hidden",
                isDesktop && "products__categories-headline--show"
              )}
            >
              {headlineText}
            </span>
            {!isDesktop && (
              <svg
                className={clsx(
                  "products__categories-chevron",
                  showDropdown && "products__categories-chevron--active"
                )}
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 10 7"
              >
                <path d="M5.004 6.613a.967.967 0 0 1-.71-.301l-4-4.155A1.064 1.064 0 0 1 0 1.419C0 1.142.106.877.294.68a.985.985 0 0 1 .71-.305c.266 0 .522.11.71.305l3.29 3.429L8.294.692a.977.977 0 0 1 1.358.054c.178.185.282.433.292.695.01.261-.076.517-.24.716l-4 4.155a.981.981 0 0 1-.7.301Z" />
              </svg>
            )}
          </h3>
          <ul
            className={clsx(
              "products__categories-list",
              isDesktop && "products__categories-list--show",
              !isDesktop && showDropdown && "products__categories-list--show"
            )}
          >
            {CATEGORIES.map((category) => (
              <React.Fragment key={category.id}>
                <li className="products__categories-item">
                  <button
                    className={clsx(
                      "products__categories-name",
                      category.id === activeProduct.categoryId &&
                        "products__categories-name--active"
                    )}
                    onClick={() => handleClickParentCategory({ category })}
                  >
                    {category.name}
                    {category.childs && (
                      <svg
                        className={clsx(
                          "products__categories-chevron",
                          category.id === activeCategoryId &&
                            "products__categories-chevron--active"
                        )}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 10 7"
                      >
                        <path d="M5.004 6.613a.967.967 0 0 1-.71-.301l-4-4.155A1.064 1.064 0 0 1 0 1.419C0 1.142.106.877.294.68a.985.985 0 0 1 .71-.305c.266 0 .522.11.71.305l3.29 3.429L8.294.692a.977.977 0 0 1 1.358.054c.178.185.282.433.292.695.01.261-.076.517-.24.716l-4 4.155a.981.981 0 0 1-.7.301Z" />
                      </svg>
                    )}
                  </button>
                  {category.childs && (
                    <ul
                      className={clsx(
                        category.id === activeCategoryId
                          ? "products__categories-childs-list"
                          : "products__categories-childs-list--hidden"
                      )}
                    >
                      {category.childs.map((child) => (
                        <li key={child.id}>
                          <button
                            className={clsx(
                              "products__categories-child-name",
                              child.id === activeProduct.categoryId &&
                                "products__categories-child-name--active"
                            )}
                            onClick={() => handleClickChildCategory({ category: child })}
                          >
                            {child.name}
                          </button>
                          {child.subchilds && (
                            <ul className="products__categories-subchilds-list">
                              {child.subchilds.map((subchild) => (
                                <li key={subchild.id}>
                                  <button
                                    className={clsx(
                                      "products__categories-child-name",
                                      subchild.id === activeProduct.categoryId &&
                                        "products__categories-child-name--active"
                                    )}
                                    onClick={() =>
                                      handleClickChildCategory({ category: subchild })
                                    }
                                  >
                                    {subchild.name}
                                  </button>
                                </li>
                              ))}
                            </ul>
                          )}
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              </React.Fragment>
            ))}
          </ul>
        </div>

        <div className="products__gallery">
          <img
            className="products__gallery-active-image"
            src={activeImage}
            alt={activeProduct.name}
          />
          <div className="products__gallery-thumbnails">
            {activeProduct.images.map((image) => (
              <div
                key={image}
                className={clsx(
                  "products__gallery-thumbnail",
                  image === activeImage && "products__gallery-thumbnail--active"
                )}
                onClick={() => setActiveImage(image)}
              >
                <img
                  className="products__gallery-thumbnail-image"
                  src={image}
                  alt={activeProduct.name}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="products__product">
          <h3
            className="products__product-name"
            dangerouslySetInnerHTML={{ __html: activeProduct.name }}
          />
          <div className="products__product-type">{activeProduct.type}</div>
          <div
            className="products__product-description"
            dangerouslySetInnerHTML={{ __html: activeProduct.description }}
          ></div>
        </div>

        {!isDesktop && (
          <a href="/pdf/catalogo.pdf" className="products__header-download">
            Descargar catálogo
          </a>
        )}
      </div>
    </div>
  );
}
