import React from 'react';

function GalleryAboutUs() {
  return (
    <>
      <div
        className='col-lg-5 col-xl-4'
        data-aos='fade-left'
      >
        <img className='img-fluid' src='../images/jpg/galeria-bolpack-2.jpg' alt='Papelera con Trayectoria' />
        <img className='img-fluid' src='../images/webp/galeria-bolpack-3.webp' alt='Impresiones a través de flexografía' />
      </div>
    </>
  );
}

export default GalleryAboutUs;